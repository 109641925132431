import React, { useState } from "react"
import {
  Link,
  generatePath,
  useParams,
  useSearchParams,
} from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { StyledPageCard } from "@/components/page-card/styled-page-card"
import { PageLoading } from "@/components/page-loading/page-loading"
import { RootPath } from "@/models/route"
import { HStack } from "@/nzds/layout"
import { useDisclosure } from "@/nzds/utils/use-disclosure/use-disclosure"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material"

import SiteInfoButton from "../../../site/components/siteInfo/siteInfoButton/siteInfoButton"
import { EditSiteConfirmation } from "../../components/edit-site-information/edit-site-confirmation"
import { FilterBar } from "../../components/filter-bar/filter-bar"
import { PlanningSteps } from "../../components/planning-steps/planning-steps"
import { PlansScatterChart } from "../../components/plans-scatter-chart/plans-scatter-chart"
import { RecommendedPlansTable } from "../../components/recommended-plans-table/recommended-plans-table"
import { SiteInfoDrawer } from "../../components/site-info-drawer/site-info-drawer"
import {
  drawerContainerGap,
  drawerWidth,
} from "../../components/site-info-drawer/site-info-drawer.constants"
import { RecommendationsPath } from "../../enums"
import { useGetGoals } from "../../services/use-get-goals/use-get-goals"
import { useGetSite } from "../../services/useGetSite/use-get-site"
import { useGetSiteRecommendations } from "../../services/useGetSiteRecommendations/use-get-site-recommendations"

export const SiteRecommendationsRoute = () => {
  const { siteId } = useParams<{ siteId: string }>()

  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const [searchParams] = useSearchParams()

  const {
    onToggle: onToggleDrawer,
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure()

  const {
    data: site,
    isLoading: isSiteLoading,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useGetSite(siteId!)

  const { data: goals, isLoading: isGoalsLoading } = useGetGoals({
    query: {
      site_id: siteId,
    },
    useQueryOptions: {
      enabled: Boolean(siteId),
    },
  })

  // only 1 goal per site, that has budget, target, etc
  const siteGoal = goals && goals.length > 0 ? goals[0] : undefined

  // default search values are from the site goal, then from query string
  const budgetSearch = searchParams.get("budget")
    ? Number(searchParams.get("budget"))
    : siteGoal?.budget

  const reductionTargetSearch = searchParams.get("reduction_target")
    ? Number(searchParams.get("reduction_target"))
    : siteGoal?.reduction_goal
      ? siteGoal.reduction_goal * 100
      : undefined

  const savedSiteExlcusions = siteGoal?.exclusions ?? []

  const recommendedPlansResult = useGetSiteRecommendations(Number(siteId), {
    query: {
      budget: budgetSearch,
      reduction_target: reductionTargetSearch,
      excluded_interventions: savedSiteExlcusions,
      optimization_protocol: "recommended_only",
    },
    useQueryOptions: {
      keepPreviousData: true,
    },
  })

  const allPlansResult = useGetSiteRecommendations(Number(siteId), {
    query: {
      excluded_interventions: savedSiteExlcusions,
      page_size: 2000,
    },
    useQueryOptions: {
      keepPreviousData: true,
    },
  })

  const isLoading =
    recommendedPlansResult.isFetching ||
    isSiteLoading ||
    isGoalsLoading ||
    allPlansResult.isFetching

  const siteGoalsHref = `${generatePath(
    `${RootPath.Recommendations}/${RecommendationsPath.SiteGoals}`,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { siteId: siteId! }
  )}?${searchParams.toString()}`

  return (
    <PageLoading
      isLoading={isLoading}
      showContent={Boolean(recommendedPlansResult.data)}
    >
      {site ? (
        <>
          <PageHeader title={`Select plan for ${site.name}`} />
          <PageHeaderActionBar hasTabs={false}>
            <HStack gap={2} justifyContent="space-between">
              <IconButton
                aria-label="Back to site goals"
                color="neutral"
                component={Link}
                to={siteGoalsHref}
              >
                <ArrowBackIosNewRoundedIcon />
              </IconButton>
              <SiteInfoButton isOpen={isDrawerOpen} onClick={onToggleDrawer} />
            </HStack>
          </PageHeaderActionBar>
          <Page>
            <EditSiteConfirmation
              onClose={() => {
                setConfirmationOpen(false)
              }}
              onContinue={() => {
                setConfirmationOpen(false)
              }}
              open={confirmationOpen}
            />
            {!recommendedPlansResult.isLoading && !allPlansResult.isLoading ? (
              <HStack gap={2} width="100%">
                <StyledPageCard
                  offset={drawerWidth + drawerContainerGap}
                  open={isDrawerOpen}
                >
                  <PlanningSteps
                    activeStep={2}
                    siteId={siteId}
                    sx={{ mb: 3 }}
                  />
                  {site.id && goals ? (
                    <FilterBar goal={siteGoal} siteId={site.id} />
                  ) : null}
                  {recommendedPlansResult.data &&
                  recommendedPlansResult.data.length > 0 &&
                  allPlansResult.data &&
                  allPlansResult.data.length > 0 ? (
                    <Stack alignItems="stretch" gap={5} width="100%">
                      <Box width="100%">
                        <PlansScatterChart
                          allPlans={allPlansResult.data}
                          recommendedPlans={recommendedPlansResult.data}
                        />
                      </Box>

                      <Box height={448} width="100%">
                        <RecommendedPlansTable
                          allPlans={allPlansResult.data}
                          recommendedPlans={recommendedPlansResult.data}
                        />
                      </Box>
                    </Stack>
                  ) : null}

                  {recommendedPlansResult.data?.length === 0 ? (
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        minHeight: (theme) => theme.typography.pxToRem(200),
                      }}
                      xs={12}
                    >
                      <Typography color="primary.main" mb={2} variant="h2">
                        No plans found
                      </Typography>
                      <Typography variant="body1">
                        Try adjusting your budget or emission target values
                      </Typography>
                    </Grid>
                  ) : null}
                </StyledPageCard>
                <SiteInfoDrawer
                  onClose={onDrawerClose}
                  open={isDrawerOpen}
                  site={site}
                />
              </HStack>
            ) : null}
          </Page>
        </>
      ) : null}
    </PageLoading>
  )
}
