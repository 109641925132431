import { useQuery } from "react-query"

import { ApiQueryName } from "@/models/api"
import { httpClient } from "@/utils"

import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import { getSafeSigmaEmbedUserEmail } from "./sigma-utils"
import {
  type SigmaEmbedConfig,
  type SigmaWorkbookSecureEmbedUrlData,
  type SigmaWorkbookSecureEmbedUrlResponse,
  allConsultantsTeamName,
  allCustomersTeamName,
} from "./sigma.types"

const fetchSigmaWorkbookSecureEmbedUrl = async (
  embedConfig: SigmaEmbedConfig
): Promise<SigmaWorkbookSecureEmbedUrlData> =>
  httpClient
    .get<SigmaWorkbookSecureEmbedUrlResponse>(
      `/api/v1/sigma_secure_embed_url`,
      {
        params: {
          email: getSafeSigmaEmbedUserEmail(embedConfig.email),
          embed_path: embedConfig.embedPath,
          external_user_team: [
            ...(embedConfig.isConsultantUser ? [allConsultantsTeamName] : []),
            allCustomersTeamName,
            embedConfig.externalUserTeam,
          ],
          hide_folder_navigation: true,
          organization_id: embedConfig.organizationId,
        },
      }
    )
    .then((response) => response.data.data)

/**
 *
 * @returns Results of the Sigma workbookId secure embeds endpoint (workbook secure embed url available for the organization, based on
 * our internal logic)
 */
export const useSigmaWorkbookSecureEmbedUrl = (
  embedConfig: SigmaEmbedConfig
) => {
  const enabled =
    Boolean(embedConfig.embedPath) && Boolean(embedConfig.externalUserTeam)
  const queryKey = createQueryKey(
    ApiQueryName.SigmaWorkbookSecureEmbedUrl,
    "getOne",
    null,
    embedConfig
  )
  const queryResult = useQuery<SigmaWorkbookSecureEmbedUrlData>({
    // We never want to cache the url because it can only be used once
    cacheTime: 0,
    enabled,
    queryKey: [queryKey],
    queryFn: () => fetchSigmaWorkbookSecureEmbedUrl(embedConfig),
    // We don't want to fetch a new url while on the page
    staleTime: Infinity,
  })

  return useRenameQueryResult(
    queryResult,
    ApiQueryName.SigmaWorkbookSecureEmbedUrl
  )
}
