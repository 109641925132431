import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { DataGuard } from "@/components/data-guard"
import {
  FeatureFlags,
  useAvailableReportDates,
  useFeature,
  useOrganization,
} from "@/services"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"
import type { Moment } from "moment"

import { Grid } from "@mui/material"

import useFilters from "../../hooks/useFilters/useFilters"
import DateFilter from "../../models/filter/dateFilter"
import SingleSelectStringFilter from "../../models/filter/singleSelectStringFilter"
import { Translation } from "../../models/i18n"
import type { IRange } from "../../models/range"
import { UnitName } from "../../models/unit"
import OrganizationDashboardScopeSection from "../../modules/dashboard/components/organizationDashboardScopeSection/organizationDashboardScopeSection"
import type {
  AllScopeEmissionsData,
  OrgDashboardDataE2eAttributes,
  OrganizationDashboardFilters,
} from "../../modules/dashboard/models/dashboard"
import { useElectricityCost } from "../../modules/dashboard/services/useElectricityCost/useElectricityCost"
import { useEmissionsStats } from "../../modules/dashboard/services/useEmissionsStats/useEmissionsStats"
import { useEnergyConsumption } from "../../modules/dashboard/services/useEnergyConsumption/useEnergyConsumption"
import type { GroupSummary } from "../../modules/dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"
import { useScope2CarbonIntensity } from "../../modules/dashboard/services/useScope2CarbonIntensity/useScope2CarbonIntensity"
import { useScopeOneEmissions } from "../../modules/dashboard/services/useScopeOneEmissions/useScopeOneEmissions"
import { useScopeTwoEmissions } from "../../modules/dashboard/services/useScopeTwoEmissions/useScopeTwoEmissions"
import {
  getAllScopeEmissionsCategories,
  getScopeEmissionsGraphData,
  getScopeOneEmissionsCategories,
  getScopeOneEmissionsGraphData,
  getScopeThreeEmissionsGraphData,
  getScopeTotalEmissions,
  getScopeTwoEmissionsGraphData,
  getScopeTwoTotalEmissions,
  getScopeTwoTotalKwh,
  sortedScopeTotals,
} from "../../modules/dashboard/util/organizationDashboardUtils"
import { useAuthentication, useScopeThreeEmissions } from "../../services"
import { KWH_PER_MWH, LBS_PER_METRIC_TON } from "../../utils/constants"
import { isDateRangeWithinBounds, isValidDateRange } from "../../utils/date"
import { CurrencyWithPlaceholder } from "../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { MonthRangeSelector } from "../date/monthRangeSelector/monthRangeSelector"
import { EmissionDecimal } from "../decimal/emissionDecimal/emissionDecimal"
import { EnergyUnitDecimal } from "../decimal/energyUnitDecimal/energyUnitDecimal"
import { PageHeaderActionBar } from "../nav/page-header-action-bar/page-header-action-bar"
import { PageHeader } from "../nav/page-header/page-header"
import { Page } from "../page/page"
import EmissionsByScope from "./emissionsByScope/emissionsByScope"
import OrganizationDashboardItem from "./organizationDashboardItem/organizationDashboardItem"
import OrganizationDashboardMetric from "./organizationDashboardMetric/organizationDashboardMetric"
import { OrganizationDashboardMetricTitle } from "./organizationDashboardMetricTitle/organizationDashboardMetricTitle"

const MIN_HEIGHT_CARD = 320
const METRIC_MIN_HEIGHT = 124

const scrollStyleThreshold = 0 // In pixels. Determined through observation

const OrgDashboardE2eAttr: OrgDashboardDataE2eAttributes = {
  totalEmissionsValue: "total-emissions-value",
  energyConsumptionValue: "energy-consumption-value",
  energyCostValue: "energy-cost-value",
  waterConsumptionValue: "water-consumption-value",
  scopeOneEmissionsValue: "scope-one-emissions-value",
  scopeThreeEmissionsValue: "scope-three-emissions-value",
}

const dashboardMetrics = {
  waterConsumption: {
    name: "Water Consumption",
    unit: "m³",
    decimalComponent: EmissionDecimal,
  },
}

export const OrganizationDashboard: React.FC = () => {
  const { filters, setFilters } = useFilters<OrganizationDashboardFilters>({
    start: DateFilter,
    org: SingleSelectStringFilter,
    end: DateFilter,
  })
  const { isLimitedAccessUser } = useAuthentication()

  const { organization, isOrganizationLoading } = useOrganization(
    filters.org?.value,
    { disabled: !filters.org?.value }
  )

  const { availableReportDatesData } = useAvailableReportDates(organization?.id)

  const { sitePreviewsData, sitePreviewsIsFetched, sitePreviewsIsLoading } =
    useSitePreviews(organization?.id)
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const { isFeatureEnabled } = useFeature()

  const { t } = useTranslation()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  const dateRange: IRange<Moment> = useMemo(
    () => ({
      start: filters.start.value,
      end: filters.end.value,
    }),
    [filters.start.value, filters.end.value]
  )

  const previousDateRange = useMemo(
    () => ({
      start: dateRange.start?.clone().subtract(1, "year"),
      end: dateRange.end?.clone().subtract(1, "year"),
    }),
    [dateRange.end, dateRange.start]
  )

  const { emissionsStats, isEmissionsStatsLoading } = useEmissionsStats(
    organization?.id,
    // Mass eslint disable
    // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    sitePreviewsData?.map((site) => site.id),
    dateRange,
    [1, 3]
  )

  const { energyConsumption, isEnergyConsumptionFetched } =
    useEnergyConsumption(organization?.id, dateRange)
  const {
    energyConsumption: previousEnergyConsumption,
    isEnergyConsumptionFetched: isPreviousEnergyConsumptionFetched,
  } = useEnergyConsumption(organization?.id, previousDateRange)

  const { electricityCost, isElectricityCostFetched } = useElectricityCost(
    organization?.id,
    dateRange
  )
  const {
    electricityCost: previousElectricityCost,
    isElectricityCostFetched: isPreviousElectricityCostFetched,
  } = useElectricityCost(organization?.id, previousDateRange)

  const { scopeOneEmissions, isScopeOneEmissionsFetched } =
    useScopeOneEmissions(organization?.id, dateRange)

  const {
    scopeOneEmissions: previousScopeOneEmissions,
    isScopeOneEmissionsFetched: isPreviousScopeOneEmissionsFetched,
  } = useScopeOneEmissions(organization?.id, previousDateRange)

  const scopeOneTotalEmissions = useMemo(
    () => getScopeTotalEmissions(scopeOneEmissions),
    [scopeOneEmissions]
  )

  const previousScopeOneTotalEmissions = useMemo(
    () => getScopeTotalEmissions(previousScopeOneEmissions),
    [previousScopeOneEmissions]
  )

  const scopeOneGraphKeys = useMemo(
    () => getScopeOneEmissionsCategories(scopeOneEmissions),
    [scopeOneEmissions]
  )

  const { scopeTwoEmissions, isScopeTwoEmissionsFetched } =
    useScopeTwoEmissions(organization?.id, dateRange)

  const {
    scopeTwoEmissions: previousScopeTwoEmissions,
    isScopeTwoEmissionsFetched: isPreviousScopeTwoEmissionsFetched,
  } = useScopeTwoEmissions(organization?.id, previousDateRange)

  const scopeTwoTotalEmissions = useMemo(
    () => getScopeTwoTotalEmissions(scopeTwoEmissions),
    [scopeTwoEmissions]
  )

  const previousScopeTwoTotalEmissions = useMemo(
    () => getScopeTwoTotalEmissions(previousScopeTwoEmissions),
    [previousScopeTwoEmissions]
  )
  const { scopeThreeEmissions, isScopeThreeEmissionsFetched } =
    useScopeThreeEmissions(organization?.id, dateRange)

  const {
    scopeThreeEmissions: previousScopeThreeEmissions,
    isScopeThreeEmissionsFetched: isPreviousScopeThreeEmissionsFetched,
  } = useScopeThreeEmissions(organization?.id, previousDateRange)

  const scopeThreeTotalEmissions = useMemo(
    () => getScopeTotalEmissions(scopeThreeEmissions),
    [scopeThreeEmissions]
  )

  const previousScopeThreeTotalEmissions = useMemo(
    () => getScopeTotalEmissions(previousScopeThreeEmissions),
    [previousScopeThreeEmissions]
  )

  const {
    emissionsStats: previousEmissionStats,
    isEmissionsStatsLoading: isPreviousEmissionsStatsLoading,
  } = useEmissionsStats(
    organization?.id,
    // Mass eslint disable
    // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    sitePreviewsData?.map((site) => site.id),
    previousDateRange,
    [1, 3]
  )

  // TODO: Remove this boolean prop once the flexible hierarchy feature is enabled by default
  const { scope2CarbonIntensityStats, isScope2CarbonIntensityLoading } =
    useScope2CarbonIntensity(organization?.id, dateRange, {
      isUseFlexibleHierarchyEnabled,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      isBundled: organization?.hasBundled,
    })

  const totalMwh = useMemo(() => {
    const organizationSummaryStats =
      scope2CarbonIntensityStats.organizationSummaryStats as
        | GroupSummary[]
        | undefined
    if (!organizationSummaryStats) {
      return NaN
    }
    return (
      organizationSummaryStats.reduce(
        (total, currentDepartmentSummary) =>
          total + currentDepartmentSummary.meteredKwh,
        0
      ) / KWH_PER_MWH
    )
  }, [scope2CarbonIntensityStats.organizationSummaryStats])

  const totalEmissions: number | null = useMemo(() => {
    if (
      !Number.isFinite(scopeOneTotalEmissions) &&
      !Number.isFinite(scopeTwoTotalEmissions) &&
      !Number.isFinite(scopeThreeTotalEmissions)
    ) {
      return null
    }
    return (
      scopeOneTotalEmissions + scopeTwoTotalEmissions + scopeThreeTotalEmissions
    )
  }, [scopeOneTotalEmissions, scopeThreeTotalEmissions, scopeTwoTotalEmissions])
  const previousTotalEmissions = useMemo(
    () =>
      previousScopeOneTotalEmissions +
      previousScopeTwoTotalEmissions +
      previousScopeThreeTotalEmissions,
    [
      previousScopeOneTotalEmissions,
      previousScopeThreeTotalEmissions,
      previousScopeTwoTotalEmissions,
    ]
  )

  const monthEmissionsScopeData: AllScopeEmissionsData = useMemo(
    () => ({
      graphData: getScopeEmissionsGraphData(
        dateRange,
        scopeOneEmissions,
        scopeTwoEmissions,
        scopeThreeEmissions
      ),
      graphKeys: getAllScopeEmissionsCategories(
        scopeOneEmissions?.length,
        scopeTwoEmissions?.length,
        scopeThreeEmissions?.length
      ),
      scopeTotals: sortedScopeTotals(
        scopeOneTotalEmissions,
        scopeTwoTotalEmissions,
        scopeThreeTotalEmissions
      ),
      totalEmissions,
    }),
    [
      dateRange,
      scopeOneEmissions,
      scopeOneTotalEmissions,
      scopeThreeEmissions,
      scopeThreeTotalEmissions,
      scopeTwoEmissions,
      scopeTwoTotalEmissions,
      totalEmissions,
    ]
  )

  const isDateRangeOverAYear: boolean =
    dateRange.end?.diff(dateRange.start, "months") >= 12

  const onDateRangeChange = useCallback(
    (newDateRange: IRange<Moment>): void => {
      setFilters({
        start: new DateFilter(newDateRange.start),
        end: new DateFilter(newDateRange.end),
      })
    },
    [setFilters]
  )

  const isValidYoY: boolean = useMemo(
    () =>
      isDateRangeWithinBounds(previousDateRange, {
        start: availableReportDatesData?.start.clone()?.startOf("month"),
        end: availableReportDatesData?.end,
      }),
    [
      availableReportDatesData?.end,
      availableReportDatesData?.start,
      previousDateRange,
    ]
  )

  useEffect(() => {
    const handleOnScroll = () => {
      setIsScrolled(window.scrollY > scrollStyleThreshold)
    }

    window.addEventListener("scroll", handleOnScroll)
    return () => {
      window.removeEventListener("scroll", handleOnScroll)
    }
  }, [])

  // Initialize date range
  useEffect(() => {
    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // Don't rewrite valid date ranges
      !isValidDateRange(
        {
          start: filters.start.value,
          end: filters.end.value,
        },
        availableReportDatesData
      )
    ) {
      setFilters(
        {
          start: new DateFilter(
            availableReportDatesData.end.clone().startOf("year")
          ),
          end: new DateFilter(
            availableReportDatesData.end.clone().endOf("month")
          ),
        },
        { replace: true }
      )
    }
  }, [
    availableReportDatesData,
    filters.end.value,
    filters.start.value,
    setFilters,
  ])

  return (
    <>
      <PageHeader title={t(Translation.Dashboard.DashboardTitle)} />
      <DataGuard
        hasData={Boolean(sitePreviewsData?.length)}
        isLoading={!sitePreviewsIsFetched}
      >
        <PageHeaderActionBar hasTabs={false}>
          <MonthRangeSelector
            availableMaxMinMonths={availableReportDatesData}
            onChange={onDateRangeChange}
            value={dateRange}
          />
        </PageHeaderActionBar>
        <Page>
          <Grid container spacing={2}>
            {
              // Metrics must be rendered separately due to various api calls gathering their data
            }
            <Grid item lg={3} md={6} xs={12}>
              <OrganizationDashboardItem
                content={
                  <OrganizationDashboardMetric
                    dataE2e={OrgDashboardE2eAttr.totalEmissionsValue}
                    decimalComponent={EmissionDecimal}
                    isDateRangeOverAYear={isDateRangeOverAYear}
                    isValidYoY={isValidYoY}
                    previousValue={previousTotalEmissions}
                    title={
                      <OrganizationDashboardMetricTitle
                        descriptor="All Sources"
                        title="Total Emissions"
                      />
                    }
                    unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
                    value={totalEmissions}
                  />
                }
                isLoading={
                  !(
                    isScopeOneEmissionsFetched &&
                    isScopeTwoEmissionsFetched &&
                    isScopeThreeEmissionsFetched &&
                    isPreviousScopeOneEmissionsFetched &&
                    isPreviousScopeTwoEmissionsFetched &&
                    isPreviousScopeThreeEmissionsFetched
                  )
                }
                minHeight={METRIC_MIN_HEIGHT}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <OrganizationDashboardItem
                content={
                  <OrganizationDashboardMetric
                    dataE2e={OrgDashboardE2eAttr.energyConsumptionValue}
                    decimalComponent={EnergyUnitDecimal}
                    isDateRangeOverAYear={isDateRangeOverAYear}
                    isValidYoY={isValidYoY}
                    // Mass eslint disable for @typescript-eslint/no-unsafe-assignment
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    previousValue={previousEnergyConsumption?.total}
                    title={
                      <OrganizationDashboardMetricTitle
                        descriptor="Elec. & Nat. Gas"
                        title="Energy Consumption"
                      />
                    }
                    unit={UnitName.GigaJoule}
                    // Mass eslint disable for @typescript-eslint/no-unsafe-assignment
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={energyConsumption?.total}
                  />
                }
                isLoading={
                  !(
                    isEnergyConsumptionFetched &&
                    isPreviousEnergyConsumptionFetched
                  )
                }
                minHeight={METRIC_MIN_HEIGHT}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <OrganizationDashboardItem
                content={
                  <OrganizationDashboardMetric
                    dataE2e={OrgDashboardE2eAttr.energyCostValue}
                    decimalComponent={CurrencyWithPlaceholder}
                    isDateRangeOverAYear={isDateRangeOverAYear}
                    isValidYoY={isValidYoY}
                    // Mass eslint disable for @typescript-eslint/no-unsafe-assignment
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    previousValue={previousElectricityCost?.total}
                    title={
                      <OrganizationDashboardMetricTitle title="Total Electricity Cost" />
                    }
                    // Mass eslint disable for @typescript-eslint/no-unsafe-assignment
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={electricityCost?.total}
                  />
                }
                isLoading={
                  !(
                    isElectricityCostFetched && isPreviousElectricityCostFetched
                  )
                }
                minHeight={METRIC_MIN_HEIGHT}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <OrganizationDashboardItem
                content={
                  <OrganizationDashboardMetric
                    dataE2e={OrgDashboardE2eAttr.waterConsumptionValue}
                    decimalComponent={
                      dashboardMetrics.waterConsumption.decimalComponent
                    }
                    isDateRangeOverAYear={isDateRangeOverAYear}
                    isValidYoY={isValidYoY}
                    // migration to strict mode batch disable
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    previousValue={previousEmissionStats?.waterEmissions}
                    title={
                      <OrganizationDashboardMetricTitle
                        title={dashboardMetrics.waterConsumption.name}
                      />
                    }
                    unitText={dashboardMetrics.waterConsumption.unit}
                    // migration to strict mode batch disable
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={emissionsStats?.waterEmissions}
                  />
                }
                isLoading={
                  isEmissionsStatsLoading ||
                  isPreviousEmissionsStatsLoading ||
                  isOrganizationLoading ||
                  sitePreviewsIsLoading
                }
                minHeight={METRIC_MIN_HEIGHT}
              />
            </Grid>
            {/* Full width content: Monthly Emissions by Scope section (FULL WIDTH) */}
            <Grid item xs={12}>
              <OrganizationDashboardItem
                content={
                  <EmissionsByScope
                    isLimitedAccessUser={isLimitedAccessUser}
                    organization={organization}
                    scopeData={monthEmissionsScopeData}
                  />
                }
                isLoading={
                  !(
                    isScopeOneEmissionsFetched &&
                    isScopeTwoEmissionsFetched &&
                    isScopeThreeEmissionsFetched
                  )
                }
                minHeight={MIN_HEIGHT_CARD}
                title="Monthly Emissions by Scope"
              />
            </Grid>
            <OrganizationDashboardScopeSection
              isLimitedAccessUser={isLimitedAccessUser}
              OrgDashboardE2eAttr={OrgDashboardE2eAttr}
              scopeOneProps={{
                isFetched: isScopeOneEmissionsFetched,
                totalEmissions: scopeOneTotalEmissions,
                graphKeys: scopeOneGraphKeys,
                graphData: getScopeOneEmissionsGraphData(
                  dateRange,
                  scopeOneEmissions,
                  scopeOneGraphKeys
                ),
              }}
              scopeThreeProps={{
                isFetched: isScopeThreeEmissionsFetched,
                totalEmissions: scopeThreeTotalEmissions,
                graphData: getScopeThreeEmissionsGraphData(scopeThreeEmissions),
              }}
              scopeTwoCarbonIntensityProps={{
                isScope2CarbonIntensityLoading,
                totalMwh,
                carbonFreeKwh: Number(
                  // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  scope2CarbonIntensityStats.energyStats?.stats.carbonFreeKwh
                    .sum
                ),
                mtCo2E:
                  Number(
                    // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    scope2CarbonIntensityStats.energyStats?.stats.co2ELbs.sum
                  ) / LBS_PER_METRIC_TON,
              }}
              scopeTwoProps={{
                isFetched:
                  isScopeTwoEmissionsFetched && isElectricityCostFetched,
                totalEmissions: scopeTwoTotalEmissions,
                totalKwh: getScopeTwoTotalKwh(scopeTwoEmissions),
                // Mass eslint disable for @typescript-eslint/no-unsafe-assignment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                totalCost: electricityCost?.total,
                graphData: getScopeTwoEmissionsGraphData(
                  dateRange,
                  scopeTwoEmissions
                ),
              }}
            />
          </Grid>
        </Page>
      </DataGuard>
    </>
  )
}

export default OrganizationDashboard
