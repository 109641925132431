import React from "react"
import type { FC } from "react"
import { Link } from "react-router-dom"

import AddRoundedIcon from "@mui/icons-material/AddRounded"
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded"
import { Button } from "@mui/material"

import type { PlanningCellProps } from "./planning-cell.types"

export const PlanningCell: FC<PlanningCellProps> = ({ siteId, planId }) => {
  return planId ? (
    <Button
      color="neutral"
      component={Link}
      size="small"
      startIcon={<ExitToAppRoundedIcon />}
      to={`/recommendations/sites/${siteId}/plans/${planId}`}
      variant="text"
    >
      View Plan
    </Button>
  ) : (
    <Button
      color="primary"
      component={Link}
      size="small"
      startIcon={<AddRoundedIcon />}
      to={`/recommendations/sites/${siteId}/goals`}
      variant="outlined"
    >
      Decarbonize Site
    </Button>
  )
}
