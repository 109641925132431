import React from "react"
import type { FC } from "react"
import { NavLink, useParams } from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { useOrganizationContext } from "@/contexts"
import { ReportPath, RootPath } from "@/models/route"
import {
  useAuthentication,
  useSigmaWorkbookEmbedUrl,
  useSigmaWorkbookSecureEmbedUrl,
} from "@/services"

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import { Box, Button } from "@mui/material"

const CustomReport: FC = () => {
  const { authenticatedUser, isConsultantUser } = useAuthentication()
  const { organization } = useOrganizationContext()
  const { workbookId } = useParams<{ workbookId: string }>()
  const { sigmaWorkbookEmbedUrlData } = useSigmaWorkbookEmbedUrl(workbookId)
  const {
    sigmaWorkbookSecureEmbedUrlData,
    sigmaWorkbookSecureEmbedUrlIsFetched,
  } = useSigmaWorkbookSecureEmbedUrl({
    email: authenticatedUser?.email,
    embedPath: sigmaWorkbookEmbedUrlData?.embedUrl,
    externalUserTeam: organization?.name,
    isConsultantUser,
    organizationId: organization?.id,
  })

  return (
    <>
      <PageHeader title="Custom Report" />
      <PageHeaderActionBar hasTabs={false}>
        <Button
          component={NavLink}
          size="small"
          startIcon={<ChevronLeftRoundedIcon />}
          to={`${RootPath.Report}/${organization?.id}/${ReportPath.CustomReports}`}
        >
          Back to Custom Reports
        </Button>
      </PageHeaderActionBar>
      <Page fullHeight>
        {sigmaWorkbookSecureEmbedUrlIsFetched && (
          <Box
            sx={{
              bottom: 0,
              left: 0,
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <iframe
              height="100%"
              src={sigmaWorkbookSecureEmbedUrlData?.secureEmbedUrl}
              style={{ border: "none" }}
              title="Custom Report"
              width="100%"
            />
          </Box>
        )}
      </Page>
    </>
  )
}

export default CustomReport
