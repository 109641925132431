// @ts-strict-ignore
import React, { useEffect, useMemo } from "react"
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { PageCard } from "@/components/page-card"
import { PageLoading } from "@/components/page-loading/page-loading"
import { SectionHeader } from "@/components/section-header/section-header"
import { RootPath } from "@/models/route"
import { HStack } from "@/nzds/layout"
import type { Schemas } from "@/services/types"
import { datadogRum } from "@datadog/browser-rum"
import { useConfirm } from "material-ui-confirm"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"
import { Box, Button, IconButton, Stack } from "@mui/material"

import { InterventionsTable } from "../../components/interventions-table/interventions-table"
import type { RowData } from "../../components/interventions-table/interventions-table.types"
import { PlanDetailsSavingsBreakdown } from "../../components/plan-details-savings-breakdown/plan-details-savings-breakdown"
import type { Breakdown } from "../../components/plan-details-savings-breakdown/plan-details-savings-breakdown.types"
import { PlanStatsBar } from "../../components/plan-stats-bar/plan-stats-bar"
import { RecommendationsPath } from "../../enums"
import { useDeleteSitePlan } from "../../services/useDeleteSitePlan/use-delete-site-plan"
import { useGetSite } from "../../services/useGetSite/use-get-site"
import { useGetSiteRecommendation } from "../../services/useGetSiteRecommendation/use-get-site-recommendation"
import { useBuildPlanNameString } from "../../utils/build-plan-name"

export const SitePlanDetailsRoute = () => {
  const { siteId, planId } = useParams<Record<"siteId" | "planId", string>>()

  const confirm = useConfirm()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: site } = useGetSite(siteId!)

  const {
    mutateAsync,
    isSuccess,
    isLoading: isDeleteSitePlanLoading,
  } = useDeleteSitePlan()

  const handleClickDeletePlan = () => {
    async function confirmThenDeletePlan() {
      await confirm({
        title: `Remove decarbonization plan from ${site?.name}?`,
        description:
          "This will remove the selected plan from this site. You can revisit this site in the 'Plan a Site' section to choose a new plan.",
        confirmationText: "Remove Plan",
        confirmationButtonProps: {
          variant: "contained",
          color: "error",
          endIcon: <DeleteRoundedIcon />,
        },
        cancellationText: "Cancel",
        cancellationButtonProps: { variant: "text", color: "neutral" },
      })

      void mutateAsync({
        organizationId: site.organizationId.toString(),
        siteId,
      })
      datadogRum.addAction("Plan removed")
    }
    void confirmThenDeletePlan()
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      navigate(`${RootPath.Recommendations}`)
    }
  }, [isSuccess, navigate])

  const location = useLocation()

  const state = location.state as
    | { goBackTo?: string; searchParams?: string }
    | undefined

  const { data: plan, isLoading: planIsloading } = useGetSiteRecommendation(
    Number(planId)
  )

  const emissionsSavingsBreakdown = useMemo(() => {
    const breakDown: Breakdown[] = []
    if (plan?.interventions) {
      for (const group in plan.interventions) {
        // calc total value
        let value = 0
        // reformat interventions
        const interventions = []

        for (const intervention in plan.interventions[group]) {
          // value += intervention.con
          interventions.push({
            name: intervention,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ...plan.interventions[group][intervention].contribution,
          })
          value +=
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            plan.interventions[group][intervention].contribution
              .emission_savings_ratio
        }

        breakDown.push({
          type: group,
          value,
          interventions,
        })
      }
    }
    return breakDown
  }, [plan?.interventions])

  const rows = useMemo(() => {
    const computedRows: RowData[] = []
    if (plan?.interventions) {
      for (const group in plan.interventions) {
        for (const intervention in plan.interventions[group]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const interventionData: Schemas["InterventionContribution"] =
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            plan.interventions[group][intervention]

          const {
            annual_emissions_savings,
            capital_cost,
            annual_cost_savings,
            emission_savings_ratio,
          } = interventionData.contribution

          computedRows.push({
            annualEmissionsSavings: annual_emissions_savings,
            interventionType: group,
            name: intervention,
            id: intervention,
            capitalCost: capital_cost,
            annualCostSavings: annual_cost_savings,
            percentEmissionSavings: emission_savings_ratio,
            roiYears: capital_cost / annual_cost_savings,
          })
        }
      }
    }

    return computedRows
  }, [plan?.interventions])

  const isLoading = planIsloading || isDeleteSitePlanLoading

  const siteRecommendationsTo = `${generatePath(
    `${RootPath.Recommendations}`
  )}${state?.searchParams ? `?${state.searchParams}` : ""}`

  const backButtonTo = state?.goBackTo ?? siteRecommendationsTo

  const { buildPlanNameString } = useBuildPlanNameString()
  const planNameFromInterventions = buildPlanNameString(plan)

  return (
    <PageLoading isLoading={isLoading} showContent={isDeleteSitePlanLoading}>
      <>
        {site ? (
          <>
            <PageHeader title={`Plan details for ${site.name}`} />
            <PageHeaderActionBar hasTabs={false}>
              <HStack gap={2} justifyContent="space-between">
                <IconButton
                  aria-label="Back to planning overview"
                  color="neutral"
                  component={Link}
                  to={backButtonTo}
                >
                  <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <HStack gap={1}>
                  <Button
                    color="neutral"
                    onClick={handleClickDeletePlan}
                    variant="text"
                  >
                    Delete plan
                  </Button>
                  <Button
                    color="primary"
                    component={Link}
                    onClick={() => {
                      datadogRum.addAction("Plan choose different plan")
                    }}
                    to={generatePath(
                      `${RootPath.Recommendations}/${RecommendationsPath.Site}`,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      { siteId: siteId! }
                    )}
                    variant="outlined"
                  >
                    Choose different plan
                  </Button>
                </HStack>
              </HStack>
            </PageHeaderActionBar>
            <Page>
              <PageCard>
                {plan ? (
                  <Stack alignItems="stretch" gap={3}>
                    {/* move actions to here after other pr is merged with full delete flow */}
                    {planNameFromInterventions ? (
                      <SectionHeader title={planNameFromInterventions} />
                    ) : null}
                    <PlanStatsBar count={rows.length} plan={plan} />
                    <Box my={2}>
                      <PlanDetailsSavingsBreakdown
                        breakdown={emissionsSavingsBreakdown}
                      />
                    </Box>
                    <InterventionsTable rows={rows} />
                  </Stack>
                ) : null}
              </PageCard>
            </Page>
          </>
        ) : null}
      </>
    </PageLoading>
  )
}
