import React from "react"
import type { FC } from "react"

import nzeroSymbol from "@/assets/images/nzero-symbol.svg"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { AiChat, AiChatUI, useAsStreamAdapter } from "@nlux/react"
import type { StreamingAdapterObserver } from "@nlux/react"
import "@nlux/themes/unstyled.css"

import BarChartIcon from "@mui/icons-material/BarChart"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { CircularProgress } from "@mui/material"

import { assistants } from "../../assistant.constants"
import type { Assistant } from "../../assistant.types"
import { createThread, sendMessage } from "../../services/assistant"
import "./assistant-chat-theme.css"
import type { AssistantChatProps } from "./assistant-chat.types"

const conversationStarters = [
  {
    icon: <BarChartIcon fontSize="small" />,
    label: "Which buildings have the highest combined EUI?",
    prompt: "What are the 5 buildings with the highest combined EUI?",
  },
  {
    icon: <TrendingUpIcon fontSize="small" />,
    label: "Which buildings have increasing energy consumption?",
    prompt:
      "What buildings have had the highest year over year increase in energy consumption?",
  },
  {
    icon: <FormatListNumberedIcon fontSize="small" />,
    label: "Rank my buildings by GHG emissions.",
    prompt:
      "Rank my buildings in order from highest GHG emissions to lowest GHG emissions.",
  },
]

export const AssistantChat: FC<AssistantChatProps> = ({
  api,
  events,
  initialConversation,
  onThreadCreate,
  thread,
  type,
}) => {
  const { organization } = useOrganizationContext()

  const assistant: Assistant | undefined = assistants.find(
    (a) => a.type === type
  )

  const assistantAdapter = useAsStreamAdapter(
    (message: string, observer: StreamingAdapterObserver): void => {
      if (!thread) {
        void createThread({
          organization_id: organization?.id ?? "",
          specialty: type,
        }).then((response) => {
          onThreadCreate(response)
          sendMessage(observer, {
            assistant_id: response.assistantId,
            messages: [
              {
                content: message,
                role: "user",
              },
            ],
            organization_id: organization?.id ?? "",
            specialty: type,
            thread_id: response.threadId,
          })
        })
      } else {
        sendMessage(observer, {
          assistant_id: thread?.assistantId ?? "",
          messages: [
            {
              content: message,
              role: "user",
            },
          ],
          organization_id: organization?.id ?? "",
          specialty: type,
          thread_id: thread?.threadId ?? "",
        })
      }
    },
    [organization, thread, type]
  )

  return (
    <AiChat
      adapter={assistantAdapter}
      api={api}
      composerOptions={{
        placeholder: "How can I help you?",
      }}
      conversationOptions={{
        conversationStarters: conversationStarters,
        historyPayloadSize: "max",
      }}
      displayOptions={{
        themeId: "nzero",
      }}
      events={events}
      initialConversation={initialConversation}
      messageOptions={{
        waitTimeBeforeStreamCompletion: "never",
      }}
      personaOptions={{
        assistant: {
          name: `${assistant?.name} Assistant`,
          avatar: nzeroSymbol as string,
          tagline: assistant?.description,
        },
      }}
    >
      <AiChatUI.Loader>
        <CircularProgress color="primary" size={24} />
      </AiChatUI.Loader>
    </AiChat>
  )
}
