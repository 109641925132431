import type { FC } from "react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom"

import { ApiQueryName } from "@/models/api"
import type { Feature } from "@/models/feature"
import { RootPath } from "@/models/route"
import type User from "@/models/user"
import { AboutIndex } from "@/modules/about"
import { AssistantIndexRoute } from "@/modules/assistant/pages"
import RecommendationsRoute from "@/modules/recommendations"
import RepRoute from "@/modules/rep"
import ReportingRoute from "@/modules/report/routes"
import Settings from "@/modules/settings/module"
import { SitesRoute } from "@/modules/site/routes"
import TamAdmin from "@/modules/tamAdmin/module"
import UtilityCredentialsRoute from "@/modules/utilityCredentials/components/utilityCredentialsRoute/utilityCredentialsRoute"
import { getAuthenticatedUserQueryKey } from "@/services"
import { useGtag } from "@/services/gtag"
import { fetchFeatures, getUser } from "@/utils"
import { queryClient } from "@/utils/queryClient"

import { ForgotPassword } from "../forgot-password/forgot-password"
import { NewLayout } from "../nav/appLayout/newLayout"
import Page404 from "../nav/page404/page404"
import OrganizationsRoute from "../organization/organizationsRoute"
import { OrganizationDashboardIndexRoute } from "../organizationDashboard/organization-dashboard-index-route"
import { OrganizationSummary } from "../organizationSummary/organizationSummary"
import { ResetPassword } from "../reset-password/reset-password"
import AdminGuard from "../route/adminGuard/adminGuard"
import { AuthenticatedRoute } from "../route/authenticated-route/authenticated-route"
import { ConsultantGuard } from "../route/consultant-guard"
import OrgGuard from "../route/orgGuard/orgGuard"
import { UnauthenticatedRoute } from "../route/unauthenticated-route/unauthenticated-route"
import UserGuard from "../route/userGuard/userGuard"
import { SignUp } from "../sign-up/sign-up"
import SignIn from "../signIn/signIn"
import { ValidateAccount } from "../validate-account/validate-account"

import "@/styles/application.scss"

const appLoader = async (): Promise<Feature[]> => {
  return queryClient.fetchQuery([ApiQueryName.Feature], fetchFeatures)
}

const authenticatedRouteLoader = async (): Promise<User> => {
  return await queryClient.fetchQuery(getAuthenticatedUserQueryKey, getUser)
}

const router = createBrowserRouter([
  {
    element: <Outlet />,
    loader: appLoader,
    children: [
      // Unauthenticated routes
      {
        path: RootPath.About,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <AboutIndex />,
          },
        ],
      },
      {
        path: RootPath.Root,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignIn />,
          },
        ],
      },
      {
        path: RootPath.SignUp,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignUp />,
          },
        ],
      },
      {
        path: `${RootPath.ValidateAccount}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ValidateAccount />,
          },
        ],
      },
      {
        path: RootPath.ForgotPassword,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: `${RootPath.ResetPassword}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: `${RootPath.UtilityCredentials}/*`,
        element: <UtilityCredentialsRoute />,
      },
      // Authenticated routes
      {
        element: <AuthenticatedRoute />,
        loader: authenticatedRouteLoader,
        children: [
          // TAM Admin pages
          {
            path: RootPath.TamAdmin,
            element: (
              <AdminGuard>
                <TamAdmin />
              </AdminGuard>
            ),
          },
          {
            path: `${RootPath.TamAdmin}/*`,
            element: (
              <AdminGuard>
                <TamAdmin />
              </AdminGuard>
            ),
          },
          // Main authenticated pages
          {
            element: <NewLayout />,
            children: [
              {
                path: RootPath.Assistant,
                element: <AssistantIndexRoute />,
              },
              {
                path: `${RootPath.Assistant}/:threadId`,
                element: <AssistantIndexRoute />,
              },
              {
                path: RootPath.Dashboard,
                element: <OrganizationDashboardIndexRoute />,
              },
              {
                path: `${RootPath.Dashboard}/:orgId`,
                element: <OrganizationDashboardIndexRoute />,
              },
              {
                path: RootPath.Organization,
                element: (
                  <UserGuard>
                    <ConsultantGuard>
                      <OrganizationsRoute />
                    </ConsultantGuard>
                  </UserGuard>
                ),
              },
              {
                path: `${RootPath.Organization}/:orgId`,
                element: (
                  <OrgGuard>
                    <UserGuard>
                      <ConsultantGuard>
                        <div className="authorized-organization">
                          <OrganizationSummary />
                        </div>
                      </ConsultantGuard>
                    </UserGuard>
                  </OrgGuard>
                ),
              },
              {
                path: RootPath.Recommendations,
                element: <RecommendationsRoute />,
              },
              {
                path: `${RootPath.Recommendations}/*`,
                element: <RecommendationsRoute />,
              },
              {
                path: RootPath.Rep,
                element: <RepRoute />,
              },
              {
                path: `${RootPath.Rep}/*`,
                element: <RepRoute />,
              },
              {
                path: RootPath.Report,
                element: <ReportingRoute />,
              },
              {
                path: `${RootPath.Report}/*`,
                element: <ReportingRoute />,
              },
              {
                path: RootPath.Settings,
                element: <Settings />,
              },
              {
                path: `${RootPath.Settings}/*`,
                element: <Page404 />,
              },
              {
                path: RootPath.Site,
                element: <SitesRoute />,
              },
              {
                path: `${RootPath.Site}/*`,
                element: <SitesRoute />,
              },
            ],
          },
        ],
      },
    ],
  },
])

export const App: FC = () => {
  useGtag()
  const { i18n } = useTranslation()

  return i18n.isInitialized ? <RouterProvider router={router} /> : null
}
