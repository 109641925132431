import React from "react"
import { Route, Routes } from "react-router-dom"

import { FeatureFlags, useFeature } from "@/services/feature"

import Page404 from "../../../../components/nav/page404/page404"
import FeatureGuard from "../../../../components/route/featureGuard/featureGuard"
import OrgGuard from "../../../../components/route/orgGuard/orgGuard"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { ReportPath } from "../../../../models/route"
import { useAuthentication } from "../../../../services/authentication"
import ActivityEmissions from "../activityEmissions/activityEmissions"
import ActivityEmissionsResolver from "../activityEmissionsResolver/activityEmissionsResolver"
import CustomReport from "../customReports/customReport/customReport"
import CustomReports from "../customReports/customReports"
import Gresb from "../gresb/gresb"
import GresbResolver from "../gresbResolver/gresbResolver"
import Gri from "../gri/gri"
import GriResolver from "../griResolver/griResolver"
import MonthlySiteData from "../monthlySiteData/monthlySiteData"
import MonthlySiteDataResolver from "../monthlySiteDataResolver/monthlySiteDataResolver"
import ReportSummary from "../reportSummary/reportSummary"
import { Reporting } from "../reporting/reporting"
import { ScopeThree } from "../scopeThree/scopeThree"
import ScopeThreeResolver from "../scopeThreeResolver/scopeThreeResolver"

const ReportRoute = (): JSX.Element => {
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const { isLimitedAccessUser } = useAuthentication()

  return (
    // TO-DO: properly set up this route component
    <Routes>
      <Route
        element={
          <OrgGuard>
            <Reporting />
          </OrgGuard>
        }
        path="/"
      />
      {!isLimitedAccessUser && (
        <Route
          element={
            <OrgGuard>
              <ScopeThreeResolver>
                <ScopeThree />
              </ScopeThreeResolver>
            </OrgGuard>
          }
          path={`/${ReportPath.ScopeThree}/*`}
        />
      )}
      <Route
        element={
          <OrgGuard>
            <ReportSummary />
          </OrgGuard>
        }
        path={`/${ReportPath.ResourceSummary}`}
      />
      {!isLimitedAccessUser && (
        <Route
          element={
            <OrgGuard>
              {/*
            isFeatureEnabled must be inside OrgGuard
            to ensure organization variable is available when directly accessing url
            Need to revamp routing in the future to make this simpler
            */}
              {isFeatureEnabled(FeatureFlags.REPORT_GRESB, organization) && (
                <GresbResolver>
                  <Gresb />
                </GresbResolver>
              )}
            </OrgGuard>
          }
          path={`/${ReportPath.Gresb}`}
        />
      )}
      {!isLimitedAccessUser && (
        <Route
          element={
            <OrgGuard>
              {/*
            isFeatureEnabled must be inside OrgGuard
            to ensure organization variable is available when directly accessing url
            Need to revamp routing in the future to make this simpler
            */}
              {isFeatureEnabled(FeatureFlags.REPORT_GRI, organization) && (
                <GriResolver>
                  <Gri />
                </GriResolver>
              )}
            </OrgGuard>
          }
          path={`/${ReportPath.Gri}`}
        />
      )}
      {!isLimitedAccessUser && (
        <Route
          element={
            <FeatureGuard feature={FeatureFlags.REPORT_ACTIVITY_EMISSIONS}>
              <OrgGuard>
                {/* TODO: update ActivityEmissions file names to match EmissionSources */}
                <ActivityEmissionsResolver>
                  <ActivityEmissions />
                </ActivityEmissionsResolver>
              </OrgGuard>
            </FeatureGuard>
          }
          path={`/${ReportPath.EmissionsSources}`}
        />
      )}
      <Route
        element={
          <FeatureGuard feature={FeatureFlags.REPORT_MONTHLY_SITE_DATA}>
            <OrgGuard>
              <MonthlySiteDataResolver>
                <MonthlySiteData />
              </MonthlySiteDataResolver>
            </OrgGuard>
          </FeatureGuard>
        }
        path={`/${ReportPath.MonthlySiteData}`}
      />
      {!isLimitedAccessUser && (
        <Route
          element={
            <FeatureGuard feature={FeatureFlags.REPORT_CUSTOM_REPORTS}>
              <OrgGuard>
                <CustomReports />
              </OrgGuard>
            </FeatureGuard>
          }
          path={`/${ReportPath.CustomReports}`}
        />
      )}
      {!isLimitedAccessUser && (
        <Route
          element={
            <FeatureGuard feature={FeatureFlags.REPORT_CUSTOM_REPORTS}>
              <OrgGuard>
                <CustomReport />
              </OrgGuard>
            </FeatureGuard>
          }
          path={`/${ReportPath.CustomReports}/:workbookId`}
        />
      )}
      {
        // Fallback for any unhandled Report route
      }
      <Route element={<Page404 />} path="*" />
    </Routes>
  )
}

export default ReportRoute
