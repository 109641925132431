import React, { useCallback, useEffect, useState } from "react"
import type { FC } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { DataGuard } from "@/components/data-guard/"
import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import Page404 from "@/components/nav/page404/page404"
import { PageHeader } from "@/components/nav/page-header/page-header"
import { PageCard } from "@/components/page-card/page-card"
import { Page } from "@/components/page/page"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { ApiQueryName } from "@/models/api"
import { FONT_WEIGHT } from "@/models/typography"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"
import { createQueryKey } from "@/services/utils/createQueryKey"
import { useAiChatApi } from "@nlux/react"
import type { ChatItem, MessageStreamStartedCallback } from "@nlux/react"

import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material"

import type { AssistantType, Message, Thread } from "../../assistant.types"
import { AssistantChat } from "../../components/assistant-chat"
import { NewThreadButton } from "../../components/new-thread-button"
import { Threads } from "../../components/threads"
import { useThreads } from "../../services/assistant/use-threads"
import type { GetThreadsParams } from "../../services/assistant/use-threads"

const convertMessagesToChatItems = (
  messages: Message[] | undefined
): ChatItem[] | undefined => {
  return messages?.map((message) => ({
    message: message.content,
    role: message.role as ChatItem["role"],
  }))
}

export const AssistantIndexRoute: FC = () => {
  const queryClient = useQueryClient()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const { sitePreviewsData, sitePreviewsIsFetched } = useSitePreviews(
    organization?.id
  )
  const navigate = useNavigate()
  const { threadId } = useParams<{ threadId: string | undefined }>()

  const assistantApi = useAiChatApi()
  const [activeAssistantType, setActiveAssistantType] =
    useState<AssistantType>("default")
  const [activeThread, setActiveThread] = useState<Thread | null>(null)
  const [initialConversation, setInitialConversation] = useState<
    ChatItem[] | undefined
  >(undefined)

  const { assistantThreadsData, assistantThreadsIsFetched } = useThreads({
    params: {
      organization_id: organization?.id ?? "",
    },
  })

  // Set active thread state when :threadId is present in the URL
  useEffect(() => {
    if (threadId && threadId !== activeThread?.threadId) {
      const thread = assistantThreadsData?.find((t) => t.threadId === threadId)

      if (thread) {
        setActiveAssistantType(thread.specialty)
        setInitialConversation(convertMessagesToChatItems(thread.messages))
        setActiveThread(thread)
      }
    }
  }, [
    activeThread?.threadId,
    assistantApi.conversation,
    assistantThreadsData,
    threadId,
  ])

  // Invalidate threads query when a new message stream is started
  // to display the new thread message/title in the list
  const handleMessageStreamStarted =
    useCallback<MessageStreamStartedCallback>((): void => {
      if (activeThread?.messages && activeThread.messages.length > 1) {
        return
      }

      const threadsParams: GetThreadsParams = {
        organization_id: activeThread?.organizationId ?? "",
      }

      const threadsQueryKey = createQueryKey(
        ApiQueryName.AssistantThreads,
        "getMany",
        null,
        threadsParams
      )

      void queryClient.invalidateQueries(threadsQueryKey)
    }, [activeThread?.messages, activeThread?.organizationId, queryClient])

  const handleNewThreadMenuItemClick = useCallback(
    (assistantType: AssistantType) => {
      setActiveAssistantType(assistantType)
      setInitialConversation(undefined)
      setActiveThread(null)
      navigate("/assistant")
    },
    [navigate]
  )

  const handleThreadClick = useCallback(
    (thread: Thread) => {
      navigate(`/assistant/${thread.threadId}`)
    },
    [navigate]
  )

  // Invalidate threads query when a new thread is created
  // to display the new thread in the list
  const handleThreadCreate = useCallback((thread: Thread) => {
    const threadsParams: GetThreadsParams = {
      organization_id: thread.organizationId,
    }

    const threadsQueryKey = createQueryKey(
      ApiQueryName.AssistantThreads,
      "getMany",
      null,
      threadsParams
    )

    setActiveThread(thread)
    void queryClient.invalidateQueries(threadsQueryKey)
  }, [])

  return isFeatureEnabled(FeatureFlags.ASSISTANT, organization) ? (
    <>
      <PageHeader title="Assistant" />
      <DataGuard
        hasData={Boolean(sitePreviewsData?.length)}
        isLoading={!sitePreviewsIsFetched}
      >
        <Page fullHeight>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={logError}
          >
            <PageCard
              sx={{
                height: "100%",
                overflow: { sm: "auto", md: "hidden" },
              }}
            >
              <Stack
                direction={{ sm: "column", md: "row" }}
                height="100%"
                gap={2}
              >
                <Stack
                  flex={{ md: "1 0 350px" }}
                  gap={2}
                  width={{ xs: "100%" }}
                  maxWidth={{ md: 350 }}
                >
                  <NewThreadButton
                    onMenuItemClick={handleNewThreadMenuItemClick}
                  />
                  <Paper
                    elevation={0}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey[50],
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      px: 1,
                      py: 2,
                    })}
                  >
                    <Stack flex={1}>
                      <Typography
                        component="h2"
                        variant="h4"
                        fontWeight={FONT_WEIGHT.bold}
                        ml={2}
                      >
                        Conversations
                      </Typography>
                      {/* Must have flex-basis for scrolling */}
                      <Box
                        sx={{
                          flex: { md: "1 1 0px" },
                          overflowY: "auto",
                        }}
                      >
                        <Threads
                          threads={assistantThreadsData}
                          isLoading={!assistantThreadsIsFetched}
                          onThreadClick={handleThreadClick}
                          selectedThread={activeThread}
                        />
                      </Box>
                    </Stack>
                  </Paper>
                </Stack>
                <Stack
                  flex="2"
                  height="100%"
                  spacing={2}
                  sx={{
                    mx: { md: "auto" },
                    maxWidth: { md: 800 },
                    paddingBottom: { sm: 3, md: 1 },
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: "calc(100% - 16px)",
                      width: "100%",
                    }}
                  >
                    {!assistantThreadsIsFetched ? (
                      <Skeleton
                        height="calc(100% - 12px)"
                        variant="rounded"
                        width="100%"
                      />
                    ) : (
                      <AssistantChat
                        api={assistantApi}
                        events={{
                          messageStreamStarted: handleMessageStreamStarted,
                        }}
                        initialConversation={initialConversation}
                        onThreadCreate={handleThreadCreate}
                        thread={activeThread}
                        type={activeAssistantType}
                      />
                    )}
                  </div>
                  <Typography
                    sx={(theme) => ({
                      alignSelf: "center",
                      color: theme.palette.grey[300],
                      margin: "0 !important",
                    })}
                    variant="caption"
                  >
                    The NZero Assistant can make mistakes. Check important info.
                  </Typography>
                </Stack>
              </Stack>
            </PageCard>
          </ErrorBoundary>
        </Page>
      </DataGuard>
    </>
  ) : (
    <Page404 />
  )
}
