import React from "react"
import type { FC } from "react"

import { DataGuard } from "@/components/data-guard"
import { PageHeader } from "@/components/nav/page-header"
import { Page } from "@/components/page"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useAuthentication } from "@/services/authentication"
import { useFeature } from "@/services/feature"
import { useSigmaWorkbooks } from "@/services/sigma"
import type { SigmaWorkbook } from "@/services/sigma"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"
import { gray200 } from "@/utils/colors"

import { Box, Divider, Typography } from "@mui/material"

import { ReportGroup, reportCards } from "../../models/report"
import type { ReportCard } from "../../models/report"
import { ReportingCard } from "../reportingCard/reportingCard"

const workbooksToReportCards = (workbooks: SigmaWorkbook[]): ReportCard[] => {
  return workbooks.map((workbook) => ({
    buttonLink: workbook.workbookId,
    buttonText: `Start Report`,
    description: "",
    group: ReportGroup.Consultant,
    title: workbook.name,
  }))
}

export const Reporting: FC = () => {
  const { organization } = useOrganizationContext()
  const { sitePreviewsData, sitePreviewsIsFetched } = useSitePreviews(
    organization?.id
  )
  const { isFeatureEnabled } = useFeature()
  const { isConsultantUser, isLimitedAccessUser } = useAuthentication()

  const { sigmaWorkbooksData: consultantWorkbooks } = useSigmaWorkbooks(
    organization?.id,
    {
      workspaceNames: ["All Consultants"],
    }
  )

  const renderReportingCards = (reportingCards: ReportCard[]) =>
    Object.keys(reportingCards).reduce((acc: JSX.Element[], key: string) => {
      const card = reportingCards[key] as ReportCard

      if (
        card.featureFlag === undefined ||
        isFeatureEnabled(card.featureFlag, organization)
      ) {
        return [
          ...acc,
          <ReportingCard key={key} card={card} organization={organization} />,
        ]
      }
      return acc
    }, [])

  const consultantReportCards = workbooksToReportCards(
    consultantWorkbooks?.entries ?? []
  )

  const group1 = reportCards.filter(
    (card) =>
      card.group === ReportGroup.One &&
      (!isLimitedAccessUser ||
        (isLimitedAccessUser && card.isVisibleToLimitedAccessUser))
  )

  const group2 = reportCards.filter(
    (card) =>
      card.group === ReportGroup.Two &&
      (!isLimitedAccessUser ||
        (isLimitedAccessUser && card.isVisibleToLimitedAccessUser))
  )

  return (
    <>
      <PageHeader title="Reporting" />
      <DataGuard
        hasData={Boolean(sitePreviewsData?.length)}
        isLoading={!sitePreviewsIsFetched}
      >
        <Page>
          <Box mb={2}>
            <Typography>Select report type</Typography>
          </Box>
          {isConsultantUser && consultantReportCards.length ? (
            <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
              {renderReportingCards(consultantReportCards)}
            </Box>
          ) : null}
          {!isConsultantUser && group1.length ? (
            <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
              {renderReportingCards(group1)}
            </Box>
          ) : null}
          {((isConsultantUser && consultantReportCards.length) ||
            (!isConsultantUser && group1.length)) &&
          group2.length ? (
            <Divider
              sx={{ marginBottom: 3, borderColor: gray200.toString() }}
            />
          ) : null}
          {group2.length ? (
            <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
              {renderReportingCards(group2)}
            </Box>
          ) : null}
        </Page>
      </DataGuard>
    </>
  )
}
