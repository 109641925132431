import React from "react"
import { useLocation } from "react-router-dom"

import { NzeroLogo } from "@/components/logo/nzeroLogo"
import type Organization from "@/models/organization"
import { RootPath } from "@/models/route"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"
import {
  LEFT_NAV_COLLAPSED_WIDTH,
  LEFT_NAV_EXPANDED_WIDTH,
  primary800,
} from "@/utils"

import {
  AccountBalance,
  Assistant,
  Bolt,
  ChevronLeft,
  HomeWork,
  Leaderboard,
  ListAlt,
  MenuRounded,
} from "@mui/icons-material"
import RadarRoundedIcon from "@mui/icons-material/RadarRounded"
import {
  Box,
  IconButton,
  List,
  Drawer as MuiDrawer,
  styled,
} from "@mui/material"

import { NavItemButton } from "./components/nav-item-button/nav-item-button"

interface NavigationDrawerProps {
  isOpen: boolean
  organization: Organization | null
  toggleDrawer: () => void
}

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: open ? LEFT_NAV_EXPANDED_WIDTH : LEFT_NAV_COLLAPSED_WIDTH,
  boxSizing: "border-box",
  flexShrink: 0,
  whiteSpace: "nowrap",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  "& .MuiDrawer-paper": {
    width: open ? LEFT_NAV_EXPANDED_WIDTH : LEFT_NAV_COLLAPSED_WIDTH,
    backgroundColor: primary800.toString(),
    borderRadius: 0,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))

export const NavigationDrawer = ({
  isOpen,
  toggleDrawer,
  organization,
}: NavigationDrawerProps) => {
  const { isFeatureEnabled } = useFeature()
  const location = useLocation()
  const activeOrganizationId = organization?.id ?? ""
  const { authenticatedUser, isConsultantUser } = useAuthentication()

  const isLimitedAccessUser = Boolean(
    authenticatedUser?.limitedAccessOrganizationIds?.includes(
      Number(activeOrganizationId)
    )
  )

  const isLimitedAccessUserBasicsEnabled = isFeatureEnabled(
    FeatureFlags.LIMITED_ACCESS_USER_BASICS,
    organization
  )

  const isNavButtonVisible =
    !isLimitedAccessUser ||
    (isLimitedAccessUser && isLimitedAccessUserBasicsEnabled)

  return (
    <Drawer open={isOpen} variant="permanent">
      <Box
        sx={{
          height: 48,
          color: (theme) => theme.palette.common.white,
          display: "flex",
          alignItems: "center",
          justifyContent: isOpen ? "space-between" : "center",
          ...(isOpen
            ? { padding: (theme) => theme.spacing(1, 1, 0, 2) }
            : null),
        }}
      >
        {isOpen ? <NzeroLogo width={80} /> : null}
        <IconButton
          aria-label="toggle drawer"
          color="inherit"
          onClick={toggleDrawer}
        >
          {isOpen ? <ChevronLeft /> : <MenuRounded />}
        </IconButton>
      </Box>
      <List>
        {isNavButtonVisible && !isConsultantUser ? (
          <NavItemButton
            dataE2e="dashboard-link"
            from={location.pathname}
            icon={<Leaderboard />}
            id="dashboard-button"
            isOpen={isOpen}
            title="Dashboard"
            to={`${RootPath.Dashboard}/${activeOrganizationId}`}
          />
        ) : null}
        {isNavButtonVisible && !isConsultantUser ? (
          <NavItemButton
            dataE2e="organization-link"
            from={location.pathname}
            icon={<AccountBalance />}
            id="organization-button"
            isOpen={isOpen}
            title="Financial"
            to={`${RootPath.Organization}/${activeOrganizationId}`}
          />
        ) : null}
        <NavItemButton
          dataE2e="sites-link"
          from={location.pathname}
          icon={<HomeWork />}
          id="sites-link"
          isOpen={isOpen}
          title="Site Explorer"
          to={`${RootPath.Site}`}
        />
        {isNavButtonVisible ? (
          <NavItemButton
            dataE2e="reporting-link"
            from={location.pathname}
            icon={<ListAlt />}
            id="reporting-button"
            isOpen={isOpen}
            title="Reporting"
            to={`${RootPath.Report}/${activeOrganizationId}`}
          />
        ) : null}
        {isFeatureEnabled(FeatureFlags.PLANNING, organization) ? (
          <NavItemButton
            dataE2e="recommendations-link"
            from={location.pathname}
            icon={<RadarRoundedIcon />}
            id="recommendations-button"
            isOpen={isOpen}
            title="Planning"
            to={`${RootPath.Recommendations}`}
          />
        ) : null}
        {!isLimitedAccessUser &&
        isFeatureEnabled(FeatureFlags.ASSISTANT, organization) ? (
          <NavItemButton
            dataE2e="assistant-link"
            from={location.pathname}
            icon={<Assistant />}
            id="assistant-button"
            isOpen={isOpen}
            title="Assistant"
            to={`${RootPath.Assistant}`}
          />
        ) : null}
        {!isLimitedAccessUser &&
        !isConsultantUser &&
        isFeatureEnabled(FeatureFlags.RECOMMENDATIONS_REP, organization) ? (
          <NavItemButton
            dataE2e="rep-link"
            from={location.pathname}
            icon={<Bolt />}
            id="rep-button"
            isOpen={isOpen}
            title="Rapid Emissions Profile"
            to={`${RootPath.Rep}`}
          />
        ) : null}
      </List>
    </Drawer>
  )
}
