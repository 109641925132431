import React from "react"
import type { FC } from "react"
import { NavLink } from "react-router-dom"

import { useOrganizationContext } from "@/contexts/organizationProvider"

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import { Button } from "@mui/material"

export const ReportingLink: FC = () => {
  const { organization } = useOrganizationContext()

  return (
    <Button
      component={NavLink}
      size="small"
      startIcon={<ChevronLeftRoundedIcon />}
      to={`/reporting/${organization?.id}`}
    >
      Report Type Selection
    </Button>
  )
}
