export enum RootPath {
  About = "/about",
  Admin = "/admin",
  Assistant = "/assistant",
  Dashboard = "/dashboard",
  // This route is made used rails
  DeveloperAdmin = "/developer_admin",
  ForgotPassword = "/forgot-password",
  NotFound = "/not-found",
  Organization = "/organizations",
  Recommendations = "/recommendations",
  Rep = "/rep",
  Report = "/reporting",
  ResetPassword = "/reset-password",
  Root = "/",
  Settings = "/settings",
  SignUp = "/sign-up",
  Site = "/sites",
  TamAdmin = "/tam-admin",
  UtilityCredentials = "/credentials",
  ValidateAccount = "/validate-account",
}

export enum AdminPath {
  Rep = "rep",
}

export enum DeveloperAdminPath {
  Bill = "/bills",
  BillingGroup = "/billing_groups",
  Department = "/departments",
  Meter = "/meters",
  MeterHour = "/meter_hours",
  Organization = "/organizations",
  OrganizationEmission = "/organization_emissions",
  Site = "/sites",
  SiteEmission = "/site_emissions",
  User = "/users",
}

export enum SitePath {
  ActivityEmissions = "activity-emissions",
  CarbonFootprint = "/carbon-footprint",
  Compliance = "compliance",
  DashboardRoot = "/",
  Electricity = "electricity",
  Emissions = "emissions",
  NaturalGas = "natural-gas",
  Overview = "overview",
  Performance = "performance",
  SiteDetails = ":siteId",
}

export enum TamAdminPath {
  BillingGroupDetail = "/onboarding/:orgId/sites/:siteId/billing-group/:billingGroupId",
  MeterDetail = "/onboarding/:orgId/sites/:siteId/billing-group/:billingGroupId/meter/:meterId",
  Onboarding = "/onboarding",
  OrgView = "/onboarding/:orgId",
  Organize = "/onboarding/:orgId/organize",
  SiteDetail = "/onboarding/:orgId/sites/:siteId",
  SiteList = "/onboarding/:orgId/sites",
}

export enum OrgViewPath {
  BillingGroup = "/billing-group",
  Department = "/department",
  Meter = "/meter",
  Site = "/site",
}
export enum ReportPath {
  CustomReports = "custom-reports",
  EmissionsSources = "emissions-sources",
  Gresb = "gresb",
  Gri = "gri",
  MonthlySiteData = "monthly-site",
  ResourceSummary = "resource-summary",
  ScopeThree = "scope-three",
}
