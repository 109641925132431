/*
 * When adding/removing a feature flag here, also add/remove it in `config/initializers/flipper.rb`
 * as well.
 */
export enum FeatureFlags {
  APP_RESELLER_CITY_OF_AVALON = "city_of_avalon_reseller",
  APP_SIGN_UP = "app/sign_up",
  ASSISTANT = "assistant",
  COGNITO_SSO = "use_cognito_for_sso",
  EPG_COST_CARBON_LINE = "epg_cost_carbon_line",
  LIMITED_ACCESS_USER_BASICS = "limited_access_user_basics",
  OAT_FLEXIBLE_HIERARCHY = "oat_flexible_hierarchy",
  PLANNING = "planning",
  RECOMMENDATIONS_REP = "recommendations/rep",
  REPORT_ACTIVITY_EMISSIONS = "report/activity_emissions",
  REPORT_ACTIVITY_EMISSIONS_NZ_DATE_RANGE_SELECTOR = "report/activity_emissions_nz_date_range_selector",
  REPORT_CUSTOM_REPORTS = "report/custom_reports",
  REPORT_GRESB = "report/gresb_report",
  REPORT_GRESB_READY_TO_REPORT = "report/gresb_ready_to_report",
  REPORT_GRESB_UNDER_REVIEW = "report/gresb_under_review",
  REPORT_GRI = "report/gri_report",
  REPORT_GRI_READY_TO_REPORT = "report/gri_ready_to_report",
  REPORT_GRI_UNDER_REVIEW = "report/gri_under_review",
  REPORT_MONTHLY_SITE_DATA = "report/monthly_site_data",
  REPORT_MONTHLY_SITE_DATA_ADVANCED_REPORTING_TOOLS = "report/monthly_site_data_advanced_reporting_tools",
  REPORT_RESOURCE_SUMMARY_ADVANCED_REPORTING_TOOLS = "report/resource_summary_advanced_reporting_tools",
  REP_EDITABLE_RESULTS_GRID = "rep/editable_results_grid",
  SETTINGS_LANGUAGE_SELECTOR = "settings_language_selector",
  SITE_EXPLORER = "site/explorer",
  USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS = "use_flexible_hierarchy_for_site_ownerships",
  UTILITY_CREDENTIALS = "utility_credentials",
}
