import type { ChangeEvent, FC, FormEvent } from "react"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useGtag } from "@/services/gtag"

import { WarningAmberRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Alert,
  Box,
  Button,
  Divider,
  Fade,
  Icon,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"

import { RootPath } from "../models/route"
import { INVALID_EMAIL, NO_ACCOUNT } from "../utils/constants"
import { If } from "./if/if"
import { WhiteBox } from "./whiteBox"

const isEmailValid = (email: string | string[]) => email.includes("@")

export const Login: FC = () => {
  const { gtagData } = useGtag()
  const theme = useTheme()
  const [errorState, setErrorState] = React.useState<symbol | null>(null)

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  const { signIn, isAuthenticating } = useAuthentication()
  const { isFeatureEnabled } = useFeature()

  const isCognitoSSOEnabled = isFeatureEnabled(FeatureFlags.COGNITO_SSO, null)
  const isSignUpEnabled = isFeatureEnabled(FeatureFlags.APP_SIGN_UP, null)

  const loginEnabled = email.length > 0 && password.length > 0

  const isNzeroUser: boolean =
    email.includes("@nzero.com") || email.includes("@ledger8760.com")

  const attemptLogin = async (event: FormEvent) => {
    event.preventDefault()
    setErrorState(null)

    if (isCognitoSSOEnabled && isNzeroUser) {
      window.location.href = "/sso_login"
    } else if (!isEmailValid(email)) {
      setErrorState(INVALID_EMAIL)
    } else {
      const result = await signIn({ email, password })

      if (result) {
        // Must set the user_id before sending the login event
        gtagData.gtag("set", {
          user_id: email,
        })

        gtagData.gtag("event", "login", {
          method: "nzero",
        })
      }

      if (!result) {
        setErrorState(NO_ACCOUNT)
      }
    }
  }

  return (
    <Fade in={true}>
      <div>
        <WhiteBox>
          <Typography mb={4} textAlign="center" variant="h1">
            Log In
          </Typography>

          <form
            onSubmit={(event) => {
              void attemptLogin(event)
            }}
          >
            {errorState === NO_ACCOUNT && (
              <Box data-e2e="login-error-message" mb={4}>
                <Alert
                  icon={<WarningAmberRounded />}
                  severity="warning"
                  variant="outlined"
                >
                  <strong>No account found.</strong>
                </Alert>
              </Box>
            )}
            <Box mb={3}>
              <TextField
                data-e2e="email-input"
                error={errorState === INVALID_EMAIL}
                fullWidth
                helperText={
                  errorState === INVALID_EMAIL &&
                  "Please include an '@' in the email address."
                }
                id="email"
                label="Email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                variant="outlined"
              />
            </Box>
            <Box mb={3}>
              <TextField
                data-e2e="password-input"
                fullWidth
                helperText={
                  <>
                    Forgot password?&nbsp;
                    <Link
                      color="inherit"
                      component={RouterLink}
                      data-e2e="reset-password-link"
                      to={RootPath.ForgotPassword}
                    >
                      Reset here
                    </Link>
                  </>
                }
                id="password"
                label="Password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.currentTarget.value)
                }}
                type="password"
              />
            </Box>
            <LoadingButton
              color="primary"
              data-e2e="login-button"
              disabled={!loginEnabled || isAuthenticating}
              fullWidth
              id="login-button"
              loading={isAuthenticating}
              type="submit"
              variant="contained"
            >
              Log In with NZero
            </LoadingButton>

            <Typography
              mb={3.5}
              mt={3.5}
              sx={{ color: theme.palette.grey[400] }}
              variant="body2"
            >
              <Divider component="span">Or</Divider>
            </Typography>
            <Stack alignItems="center">
              <Button
                color="primary"
                endIcon={<Icon fontSize="small">key</Icon>}
                href="/sso_login"
                variant="outlined"
              >
                Log In with SSO
              </Button>
            </Stack>
          </form>

          <If condition={isSignUpEnabled}>
            <Box mb={4.5} mt={4.5}>
              <Divider />
            </Box>
            <Box textAlign="center">
              <Typography mb={1} variant="body2">
                Don&rsquo;t have an account?
              </Typography>
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to={RootPath.SignUp}
                variant="outlined"
              >
                Sign Up
              </Button>
            </Box>
          </If>
        </WhiteBox>
      </div>
    </Fade>
  )
}
