import React from "react"
import type { FC } from "react"
import { NavLink } from "react-router-dom"

import { primary600, primary700 } from "@/utils/colors"
import { datadogRum } from "@datadog/browser-rum"

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material"

interface NavItemButtonProps {
  dataE2e?: string
  from: string
  icon: JSX.Element
  id: string
  isOpen: boolean
  title: string
  to: string
}

export const NavItemButton: FC<NavItemButtonProps> = ({
  dataE2e,
  from,
  icon,
  isOpen,
  id,
  title,
  to,
}) => (
  <ListItem key={title} disablePadding sx={{ display: "block" }}>
    <Tooltip disableHoverListener={isOpen} placement="right" title={title}>
      <ListItemButton
        component={NavLink}
        data-e2e={dataE2e}
        id={id}
        onClick={() => {
          datadogRum.addAction("navigate", {
            description: `main nav: ${title}`,
            from: from,
            to: to,
          })
        }}
        sx={{
          color: (theme) => theme.palette.common.white,
          minHeight: 48,
          justifyContent: isOpen ? "initial" : "center",
          "&.active": {
            backgroundColor: (theme) => theme.palette.primary.dark,
            color: (theme) => theme.palette.common.white,
            ":hover": {
              backgroundColor: primary600.toString(),
            },
          },
          "&.MuiListItemButton-root:not(.active):hover": {
            backgroundColor: primary700.toString(),
          },
        }}
        to={to}
      >
        <ListItemIcon
          sx={{
            color: (theme) => theme.palette.common.white,
            minWidth: 0,
            mr: isOpen ? 1 : "auto",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        {isOpen ? (
          <ListItemText
            primary={<Typography variant="body2">{title}</Typography>}
          />
        ) : null}
      </ListItemButton>
    </Tooltip>
  </ListItem>
)
