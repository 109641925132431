import { useQuery } from "react-query"

import { ApiQueryName } from "@/models/api"

import { useAuthentication } from "../authentication"
import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import { allCustomersWorkspaceName } from "./sigma.types"
import type {
  SigmaPaginatedResponse,
  SigmaWorkbook,
  SigmaWorkbookName,
} from "./sigma.types"
import { useSigmaApi } from "./useSigmaApi"

// Filtering is necessary because the user associated with the API secret
// has access to all org teams/workspaces
const filterWorkbooks = (
  workbooks: SigmaWorkbook[],
  workspaceNames: string[]
): SigmaWorkbook[] => {
  const accessibleWorkbooks = workbooks.filter((workbook) =>
    workspaceNames
      .map((name) => name.toLowerCase())
      .includes(workbook.path.toLowerCase())
  )

  return accessibleWorkbooks
}

interface UseSigmaWorkbooksOptions {
  workspaceNames?: SigmaWorkbookName[]
}

const defaultOptions: Required<UseSigmaWorkbooksOptions> = {
  workspaceNames: [],
}

/**
 *
 * @returns A list of Sigma workbooks available for the organization
 */
export const useSigmaWorkbooks = (
  organizationName: string,
  options: UseSigmaWorkbooksOptions = defaultOptions
) => {
  const { isConsultantUser } = useAuthentication()
  const { fetchSigmaWorkbooks, sigmaAccessTokenIsFetched } = useSigmaApi()

  const enabled = sigmaAccessTokenIsFetched && !!organizationName

  const queryKey = createQueryKey(
    ApiQueryName.SigmaWorkbooks,
    "getMany",
    organizationName
  )

  const finalOptions: Required<UseSigmaWorkbooksOptions> = {
    ...defaultOptions,
    ...options,
  }

  const workspaceNames: string[] = [
    ...(isConsultantUser
      ? finalOptions.workspaceNames
      : finalOptions.workspaceNames.filter(
          (workspaceName) => workspaceName !== "All Consultants"
        )),
    allCustomersWorkspaceName,
    organizationName,
  ]

  const queryResult = useQuery<SigmaPaginatedResponse<SigmaWorkbook>>({
    enabled,
    queryFn: fetchSigmaWorkbooks,
    queryKey: [queryKey],
    select: (data) => ({
      ...data,
      entries: filterWorkbooks(data?.entries ?? [], workspaceNames),
    }),
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SigmaWorkbooks)
}
