import React, { useEffect } from "react"
import type { FC } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { OrganizationResolver } from "@/components/organizationResolver"
import {
  SelectedDateRangeProvider,
  SiteProvider,
  SiteSearchProvider,
  SiteSearchResultsActiveProvider,
  ViewportProvider,
} from "@/contexts"
import { RootPath } from "@/models/route"
import { UserSettingsProvider } from "@/modules/settings"
import { useAuthentication } from "@/services/authentication"
import { useDatadogRumSetUser } from "@/services/datadog-rum"
import { useGtagSetUser } from "@/services/gtag"
import { usePendoInit } from "@/services/pendo"
import { getCurrentAppUrl } from "@/utils"
import { LogoutProvider } from "@/utils/logout-context"

export const AuthenticatedRoute: FC = () => {
  const { isAuthenticated } = useAuthentication()
  useDatadogRumSetUser()
  // Set the gtag user_id here if user loads a page and is already authenticated
  useGtagSetUser()
  usePendoInit()
  const navigate = useNavigate()

  // Redirect to sign in if not authenticated
  useEffect(() => {
    const state = { from: { pathname: getCurrentAppUrl() } }

    if (!isAuthenticated) {
      navigate(RootPath.Root, { state })
    }
  }, [isAuthenticated, navigate])

  return (
    isAuthenticated && (
      <LogoutProvider>
        <UserSettingsProvider>
          <SiteProvider>
            <ViewportProvider>
              <SelectedDateRangeProvider>
                <SiteSearchProvider>
                  <SiteSearchResultsActiveProvider>
                    <OrganizationResolver>
                      <Outlet />
                    </OrganizationResolver>
                  </SiteSearchResultsActiveProvider>
                </SiteSearchProvider>
              </SelectedDateRangeProvider>
            </ViewportProvider>
          </SiteProvider>
        </UserSettingsProvider>
      </LogoutProvider>
    )
  )
}
