import type { FC } from "react"
import React, { useEffect, useMemo } from "react"
import type { SubmitHandler } from "react-hook-form"
import {
  Link,
  generatePath,
  useNavigate,
  useSearchParams,
} from "react-router-dom"

import { useLocations } from "@/services/location"
import { useGetBuildingTypes } from "@/services/site"
import { useUpdateSiteModel } from "@/services/site/useUpdateSite"
import { datadogRum } from "@datadog/browser-rum"
import type { ValidateFunction } from "ajv"
import Ajv from "ajv"

import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import {
  Alert,
  Button,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

import { SectionHeader } from "../../../../components/section-header/section-header"
import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import Address from "../../../../models/address"
import type Location from "../../../../models/location"
import PhysicalDetail from "../../../../models/physcialDetails"
import { RootPath } from "../../../../models/route"
import type { Option } from "../../../../nzds"
import { HStack } from "../../../../nzds"
import { NZeroLogoIcon } from "../../../../nzds/data-display/nzero-logo-icon/nzero-logo-icon"
import { FormMultiSelectCheckboxField } from "../../../../nzds/forms/form-multi-select-checkbox-field/form-multi-select-checkbox-field"
import { FormNumericTextField } from "../../../../nzds/forms/form-numeric-text-field/form-numeric-text-field"
import { FormSelectField } from "../../../../nzds/forms/form-select-field/form-select-field"
import { FormTextField } from "../../../../nzds/forms/form-text-field/form-text-field"
import { Form } from "../../../../nzds/forms/form/form"
import { SubmitButton } from "../../../../nzds/forms/submit-button/submit-button"
import { useForm } from "../../../../nzds/forms/use-form/use-form"
import { useCountryOptions } from "../../../../nzds/utils/useCountryOptions/useCountryOptions"
import { useStateOptions } from "../../../../nzds/utils/useStateOptions/useStateOptions"
import { RecommendationsPath } from "../../enums"
import { useCreateGoal } from "../../services/use-create-goal/use-create-goal"
import { useUpdateGoal } from "../../services/use-udpate-goal/use-update-goal"
import { useInterventionOptions } from "../../services/useGetInterventions/use-intervention-options"
import { snakeToTitleCase } from "../../utils/snakeToTitleCase"
import {
  AccordionCard,
  AccordionCardContent,
  AccordionCardTitle,
} from "../accordion/accordion-card"
import { renderInterventionValue } from "../filter-bar/render-intervention-value"
import { buildPhysicalDetailsDefaultValues } from "./build-physical-details-default-values"
import { buildPhysicalDetailsModelValues } from "./build-physical-details-model-values"
import type { Schema } from "./schema"
import { schema } from "./schema"
import { SchemaField } from "./schema-field"
import { useSectionToIconMap } from "./section-to-icon-map"
import type { SiteGoalsFormProps } from "./site-goals-form.types"

const ajv = new Ajv()

/**
 * used two main ways
 * - saved as goals for this site
 * - used to check if site was modified in the form by excluding these
 * from the field state check(dirty)
 */
const goalFields = ["budget", "reduction_target", "excluded_interventions"]

export const SiteGoalsForm: FC<SiteGoalsFormProps> = ({
  site,
  physicalDetailTypes,
  goals,
  onSubmitSuccess,
}) => {
  // only 1 goal per site, that has budget, target, etc
  const siteGoal = goals.length > 0 ? goals[0] : undefined
  const iconMap = useSectionToIconMap()
  const validators = useMemo(() => {
    const validatorsByType: Record<string, ValidateFunction> = {}
    for (const pDT of physicalDetailTypes) {
      const func = ajv.compile(pDT.schema)
      const { id } = pDT
      if (id) {
        validatorsByType[id] = func
      }
    }
    return validatorsByType
  }, [physicalDetailTypes])

  const defaultPhysicalDetails = buildPhysicalDetailsDefaultValues(
    physicalDetailTypes,
    site.physicalDetails
  )

  const [searchParams] = useSearchParams()
  const { buildingTypesData, buildingTypesIsLoading } = useGetBuildingTypes()
  const countryOptions = useCountryOptions()

  const form = useForm<typeof schema>({
    schema,
    defaultValues: {
      numberOfFloors: site.numberOfFloors?.toString() ?? "",
      sqFt: site.sqFt?.toString() ?? "",
      yearBuilt: site.yearBuilt?.toString() ?? "",
      locationId: site.locationId?.toString() ?? "",
      buildingType: site.buildingType ?? "",
      reduction_target: siteGoal?.reduction_goal
        ? `${siteGoal.reduction_goal * 100}`
        : "",
      budget: siteGoal?.budget ? `${siteGoal.budget}` : "",
      excluded_interventions: siteGoal?.exclusions ? siteGoal.exclusions : [],
      address: {
        address_line_1: site.address?.address_line_1 ?? "",
        address_line_2: site.address?.address_line_2 ?? "",
        city: site.address?.city ?? "",
        country: site.address?.country ?? "",
        postal_code: site.address?.postal_code ?? "",
        state: site.address?.state ?? "",
      },
      physical_details: defaultPhysicalDetails,
    },
  })

  const currencySymbol = useCurrencySymbol()

  const {
    formState: { isSubmitSuccessful, dirtyFields, isSubmitting },
    getValues,
    reset,
    resetField,
    watch,
  } = form

  const currentFormCountry = watch("address.country")
  const currentFormState = watch("address.state")

  const isStateRequired = ["US", "CA"].includes(currentFormCountry)

  const stateOptions = useStateOptions(currentFormCountry)

  useEffect(() => {
    // if the current state is not in the list of states,
    // reset it
    if (!currentFormCountry || !currentFormState) {
      return
    }
    const currentStateInStates = stateOptions.some(
      ({ value }) => value === currentFormState
    )
    if (!currentStateInStates) {
      resetField("address.state", { defaultValue: "" })
    }
  }, [currentFormCountry, currentFormState, resetField, stateOptions])

  const { mutateAsync, isSuccess } = useUpdateSiteModel()

  const { mutateAsync: createGoal, isSuccess: isCreateGoalSuccess } =
    useCreateGoal()
  const { mutateAsync: updateGoal, isSuccess: isUpdateGoalSuccess } =
    useUpdateGoal()

  const { locations } = useLocations()

  const interventionOptions = useInterventionOptions()

  const navigate = useNavigate()

  const locationOptions: Option[] = useMemo(() => {
    let options: Option[] = []

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!locations) {
      return []
    }
    options = (locations as unknown as Location[]).map((location) => ({
      value: location.id ?? location.name,
      label: location.name,
    }))
    return options
  }, [locations])

  const siteRecommendationsHref = generatePath(
    `${RootPath.Recommendations}/${RecommendationsPath.Site}`,
    { siteId: site.id?.toString() ?? null }
  )

  const handleSubmit: SubmitHandler<Schema> = async ({
    reduction_target,
    excluded_interventions,
    budget,
    yearBuilt,
    sqFt,
    locationId,
    buildingType,
    numberOfFloors,
    address,
    physical_details,
  }) => {
    if (site.id) {
      if (site.address) {
        if (address.address_line_1) {
          site.address.address_line_1 = address.address_line_1
        }
        if (address.address_line_2) {
          site.address.address_line_2 = address.address_line_2
        }
        if (address.city) {
          site.address.city = address.city
        }
        if (address.state) {
          site.address.state = address.state
        }
        if (address.country) {
          site.address.country = address.country
        }
        if (address.postal_code) {
          site.address.postal_code = address.postal_code
        }
      } else {
        site.address = new Address(address)
      }

      site.yearBuilt = Number(yearBuilt)
      site.sqFt = sqFt
      site.locationId = locationId
      site.buildingType = buildingType
      site.numberOfFloors = Number(numberOfFloors)

      // convert number types to numbers instead of strings
      const processedPd = buildPhysicalDetailsModelValues(
        physicalDetailTypes,
        physical_details as Pick<
          PhysicalDetail,
          "details" | "physicalDetailTypeId"
        >[]
      )

      if (physical_details) {
        site.physicalDetails = processedPd.map(
          (
            details: Pick<PhysicalDetail, "details" | "physicalDetailTypeId">
          ) => {
            const existingSitePhysicalDetail = site.physicalDetails?.find(
              (siteDetail) =>
                siteDetail.physicalDetailTypeId?.toString() ===
                details.physicalDetailTypeId?.toString()
            )
            if (existingSitePhysicalDetail) {
              // update the instance
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              existingSitePhysicalDetail.details = details.details
              return existingSitePhysicalDetail
            }
            // create new
            return new PhysicalDetail({
              subjectId: site.id,
              subjectType: "Site",
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              details: details.details,
              physicalDetailTypeId: details.physicalDetailTypeId,
            })
          }
        )
      }

      processedPd.forEach(
        (pD: Pick<PhysicalDetail, "details" | "physicalDetailTypeId">) => {
          let validate
          if (pD.physicalDetailTypeId) {
            validate = validators[pD.physicalDetailTypeId]
          }
          if (validate) {
            const valid = validate(pD.details)
            if (!valid) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              console.warn({ valid, errors: validate.errors, data: pD.details })
            }
          }
        }
      )

      await mutateAsync({ site })
    }

    // goals
    if (siteGoal) {
      await updateGoal({
        parameters: {
          organization_id: site.organizationId.toString(),
          path: { goal_id: siteGoal.id },
        },
        requestBody: {
          ...siteGoal,
          reduction_goal: reduction_target
            ? Number(reduction_target) / 100
            : undefined,
          budget: budget ? Number(budget) : undefined,
          exclusions: excluded_interventions ?? undefined,
          organization_id: site.organizationId.toString(),
        },
      })
    } else {
      await createGoal({
        subject_id: Number(site.id),
        subject_type: "Site",
        reduction_goal: reduction_target
          ? Number(reduction_target) / 100
          : undefined,
        budget: budget ? Number(budget) : undefined,
        exclusions: excluded_interventions ?? undefined,
        organization_id: site.organizationId.toString(),
      })
    }
  }

  useEffect(() => {
    if (
      isSubmitSuccessful &&
      isSuccess &&
      (isCreateGoalSuccess || isUpdateGoalSuccess)
    ) {
      const currentFormValues = getValues()

      const dirtyFieldsArray = Object.entries(dirtyFields) as [
        keyof Schema,
        boolean,
      ][]

      const isSiteModified = dirtyFieldsArray.some(([fieldName, isDirty]) => {
        if (goalFields.includes(fieldName)) {
          return false // do not worry about these fields as only query string values
        }
        return isDirty
      })

      datadogRum.addAction("Goals saved", {
        siteModified: isSiteModified,
        budget: currentFormValues.budget,
        reductionTarget: currentFormValues.reduction_target,
        excludedInterventions:
          currentFormValues.excluded_interventions?.join("|"),
      })

      reset(currentFormValues) // also resets isDirty and what not
      // check if site changed, if it has, call submit success, if only goals
      // navigate
      if (isSiteModified) {
        onSubmitSuccess()
      } else {
        navigate(`${siteRecommendationsHref}?${searchParams.toString()}`)
      }
    }
  }, [
    dirtyFields,
    getValues,
    isCreateGoalSuccess,
    isSubmitSuccessful,
    isSuccess,
    isUpdateGoalSuccess,
    navigate,
    onSubmitSuccess,
    reset,
    searchParams,
    siteRecommendationsHref,
  ])

  return (
    <Form form={form} onSubmit={handleSubmit}>
      {!buildingTypesIsLoading && locationOptions.length > 0 ? (
        <Stack gap={2}>
          <SectionHeader title="Set Site Goals (Optional)" />
          <HStack gap={2}>
            <FormNumericTextField<Schema>
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{ maxLength: "3" }}
              label="Emission Reduction"
              name="reduction_target"
              NumericFormatProps={{
                decimalScale: 0,
                allowNegative: false,
                thousandsGroupStyle: "none",
                thousandSeparator: "",
              }}
              sx={{ flex: 1 }}
            />
            <FormNumericTextField<Schema>
              fullWidth
              id="max_budget"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                ),
              }}
              label="Max budget"
              name="budget"
              NumericFormatProps={{
                decimalScale: 0,
                allowNegative: false,
              }}
              sx={{ flex: 1 }}
            />
            <FormMultiSelectCheckboxField
              autoWidth
              checkboxes={interventionOptions}
              label="Exclude Interventions"
              name="excluded_interventions"
              renderValue={renderInterventionValue(interventionOptions)}
              sx={{ flex: "1" }}
            />
          </HStack>
          <SectionHeader title="Add Site Information (Recommended)" />
          <Alert icon={<NZeroLogoIcon color="primary" />} severity="info">
            <Typography
              component="span"
              fontWeight="fontWeightBold"
              variant="body2"
            >
              Pro Tip:
            </Typography>{" "}
            <Typography component="span" variant="body2">
              Improve recommendation accuracy by adding additional site
              information.
            </Typography>
          </Alert>

          <Card variant="outlined">
            <HStack
              alignItems="center"
              bgcolor="grey.25"
              gap={1}
              p={2}
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <HomeWorkOutlinedIcon color="neutral" />
              <Typography component="h3" variant="h3">
                Building Information
              </Typography>
            </HStack>
            <CardContent>
              <Stack alignItems="stretch" gap={2}>
                <Stack alignItems="stretch" gap={2}>
                  <Typography
                    component="h4"
                    fontWeight="fontWeightBold"
                    variant="body1"
                  >
                    Location
                  </Typography>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <FormTextField<Schema>
                        fullWidth
                        label="Address Line 1"
                        name="address.address_line_1"
                        required
                      />
                    </Grid>
                    <Grid xs={6}>
                      <FormTextField<Schema>
                        fullWidth
                        label="Address Line 2"
                        name="address.address_line_2"
                      />
                    </Grid>
                    <Grid xs={3}>
                      <FormTextField<Schema>
                        fullWidth
                        label="City"
                        name="address.city"
                        required
                      />
                    </Grid>
                    <Grid xs={3}>
                      <FormNumericTextField<Schema>
                        fullWidth
                        label="Zip Code"
                        name="address.postal_code"
                        NumericFormatProps={{
                          decimalScale: 0,
                          allowNegative: false,
                          thousandsGroupStyle: "none",
                          thousandSeparator: "",
                        }}
                        placeholder="Enter zip code"
                        required
                      />
                    </Grid>
                    <Grid xs={3}>
                      <FormSelectField<Schema>
                        fullWidth
                        label="Country"
                        name="address.country"
                        options={countryOptions}
                        placeholder="Select country"
                        required
                      />
                    </Grid>
                    <Grid xs={3}>
                      <FormSelectField<Schema>
                        fullWidth
                        label="State"
                        name="address.state"
                        options={stateOptions}
                        placeholder="Select state"
                        required={isStateRequired}
                      />
                    </Grid>

                    <Grid xs={3}>
                      <FormSelectField<Schema>
                        fullWidth
                        label="Location"
                        name="locationId"
                        options={locationOptions}
                        placeholder="Select site location"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack alignItems="stretch" gap={2}>
                  <Typography
                    component="h4"
                    fontWeight="fontWeightBold"
                    variant="body1"
                  >
                    Building Details
                  </Typography>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid xs={3}>
                      <FormSelectField<Schema>
                        fullWidth
                        label="Building Type"
                        name="buildingType"
                        options={
                          buildingTypesData?.map((type) => {
                            return {
                              label: type,
                              value: type,
                            }
                          }) ?? []
                        }
                        placeholder="Select building type"
                        required
                      />
                    </Grid>
                    <Grid xs={3}>
                      <FormNumericTextField<Schema>
                        fullWidth
                        label="Building Area (sq. ft)"
                        name="sqFt"
                        NumericFormatProps={{
                          decimalScale: 0,
                          allowNegative: false,
                        }}
                        required
                      />
                    </Grid>
                    <Grid xs={3}>
                      <FormTextField<Schema>
                        fullWidth
                        label="Year Built"
                        name="yearBuilt"
                        required
                      />
                    </Grid>

                    <Grid xs={3}>
                      <FormNumericTextField<Schema>
                        fullWidth
                        label="Number of Floors"
                        name="numberOfFloors"
                        NumericFormatProps={{
                          decimalScale: 0,
                          allowNegative: false,
                        }}
                        required
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </CardContent>
          </Card>

          {physicalDetailTypes.length > 0 ? (
            <>
              {physicalDetailTypes.map((physicalDetailType, index) => {
                const { schema: physicalDetailTypeSchema } = physicalDetailType
                const icon = iconMap[physicalDetailType.name]
                return (
                  <AccordionCard
                    key={physicalDetailType.id}
                    aria-controls={`${physicalDetailType.name}-content`}
                    id={physicalDetailType.name}
                    onChange={(_, expanded) => {
                      if (expanded) {
                        datadogRum.addAction("Site metadata section expanded", {
                          section: physicalDetailType.name,
                        })
                      }
                    }}
                  >
                    <AccordionCardTitle icon={icon}>
                      {physicalDetailTypeSchema.title ??
                        snakeToTitleCase(physicalDetailType.name)}
                    </AccordionCardTitle>
                    <AccordionCardContent>
                      <SchemaField
                        name={`physical_details.${index}.details`}
                        schema={physicalDetailTypeSchema}
                      />
                    </AccordionCardContent>
                  </AccordionCard>
                )
              })}
            </>
          ) : null}

          <HStack gap={2}>
            <SubmitButton disableFormStateCheck>
              Save and generate plans
            </SubmitButton>
            <Button
              component={Link}
              disabled={isSubmitting}
              onClick={() => {
                datadogRum.addAction("Goals skipped")
              }}
              to={`${siteRecommendationsHref}?${searchParams.toString()}`}
              variant="text"
            >
              Skip
            </Button>
          </HStack>
        </Stack>
      ) : null}
    </Form>
  )
}
